html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent;
  &:active, &:hover {
    outline: 0; } }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  color: #000;
  background: #ff0; }

small {
  font-size: 80%; }

sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit; }

button {
  overflow: visible;
  text-transform: none; }

select {
  text-transform: none; }

button, html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer; }

input {
  &[type="reset"], &[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer; } }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  &::-moz-focus-inner {
    padding: 0;
    border: 0; }
  line-height: normal;
  &[type="checkbox"], &[type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  &[type="number"] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      height: auto; } }
  &[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    &::-webkit-search-cancel-button, &::-webkit-search-decoration {
      -webkit-appearance: none; } } }

fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: 1px solid #c0c0c0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td, th {
  padding: 0; }
